import React from 'react';
import './memories.css';
import { default as ImageUpload } from '../imageUpload/ImageUpload.js';

const Memories = ({loaderStatusHandler}) => {
  return (
    <div id='upload' className='memories-container'>
        <div className='memories-text-container'>
            <div className='memories_item-container-title'>
                <p>Naše vjenčanje</p>
                <p>Iz vaše perspektive</p>
            </div>
        </div>
        <div className='memories-uploadBox-container'>
            <ImageUpload loaderStatusHandler={loaderStatusHandler}/>
        </div>
    </div>
  );
};

export default Memories;
