import React from 'react';
import './grid.css';
import { useEffect, useState } from 'react';
import { fetchImagesFromStorage } from '../../services/ImageService.js';

const Grid = () => {
  // Split models into chunks of 5 items per row
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = await fetchImagesFromStorage();
      setImages(fetchedImages);
    };

    fetchImages();
  }, []);

  return (
    <div>
    {images.length > 0 && (
      <div className="grid-container">
            {images.map((model, rowIndex) => (
                <div className="grid-item" key={rowIndex}>
                <img src={model} className="grid-image"></img>
                </div>
            ))}
        </div>
    )}
    </div>
  );
};

export default Grid;
