import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { google_api_key } from '../../services/GoogleApp.js';

// Define map container style
const containerStyle = {
  width: '100%', // Adjust to your desired width
  height: '200pt', // Adjust to your desired height
  borderRadius: '16px', // Rounded corners like in your image
  overflow: 'hidden',
};

// Define map options without controls like zoom and fullscreen
const options = {
  disableDefaultUI: true, // Disable all UI controls
  gestureHandling: 'none', // Disable zoom on scroll
};


const MapComponent = ({center}) => {

    const onMapClick = ({ e }) => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${center.lat},${center.lng}`;
        window.open(url, '_blank');
    };

  return (
    <LoadScript googleMapsApiKey={google_api_key}> 
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
            options={options}
            onClick={onMapClick}
        >
            <Marker position={center} />
        </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
