import React from 'react'
import './timer.css';
import { useState, useEffect } from 'react';

const VerticalWhiteLine = () => {
    return <div className='white_line'></div>;
};

const Timer = () => {
    const padNumber = (number) => {
      return number < 10 ? `0${number}` : number;
    };

    const daysFormatted = (number) => {
        if (number % 100 >= 10 && number % 100 < 20) {
            return "dana";
        } 

        return number % 10 === 1 ? "dan" : "dana";
    };

    const hoursFormatted = (number) => {
        if (number >= 10 && number < 20) {
            return "sati";
        } 

        if (number % 10 === 1) {
            return "sat";
        } 

        if (number % 10 >= 2 && number % 10 < 5) {
            return "sata";
        }

        return "sati";
    };

    const minutesFormatted = (number) => {
        if (number >= 10 && number < 20) {
            return "minuta";
        } 

        if (number % 10 >= 2 && number % 10 < 5) {
            return "minute";
        }

        return "minuta";
    };

    const secondsFormatted = (number) => {
        if (number >= 10 && number < 20) {
            return "sekundi";
        }

        if (number % 10 === 1) {
            return "sekunda";
        } 

        if (number % 10 >= 2 && number % 10 < 5) {
            return "sekunde";
        }

        return "sekundi";
    };

    const timeComponent = (title, subtitle) => {
        return (
        <div className='container_time_column'>
            <div className='container_time-title'>
                <p>{title}</p>
            </div>
            <div className='container_time-subtitle'>
                <p>{subtitle}</p>
            </div>
        </div>
        )
    }

    const calculateTimeLeft = () => {
        const targetDate = new Date('2024-10-05T17:30:00');
        const now = new Date();
        const difference = targetDate - now;
    
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: padNumber(Math.max(Math.floor(difference / (1000 * 60 * 60 * 24)), 0)),
            hours: padNumber(Math.max(Math.floor((difference / (1000 * 60 * 60)) % 24), 0)),
            minutes: padNumber(Math.max(Math.floor((difference / 1000 / 60) % 60), 0)),
            seconds: padNumber(Math.max(Math.floor((difference / 1000) % 60), 0)),
          };
        }
    
        return timeLeft;
    };

    const isTimerZero = () => {
        return timeLeft.days === "00" && timeLeft.hours === "00" && timeLeft.minutes === "00" && timeLeft.seconds === "00";
    }
    
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000); // Update every second

        return () => clearTimeout(timer);
    });
    
    return (
    <div className='countdown_container'>
        <div className='countdown_title'>
            <p>{isTimerZero() ? "Zauvijek je počelo!" : "Zauvijek počinje uskoro!"}</p>
        </div>

        {!isTimerZero() && (
            <div className='countdown_time'>
                {timeComponent(timeLeft.days, daysFormatted(timeLeft.days))}
                <VerticalWhiteLine />
                {timeComponent(timeLeft.hours, hoursFormatted(timeLeft.hours))}
                <VerticalWhiteLine />
                {timeComponent(timeLeft.minutes, minutesFormatted(timeLeft.minutes))}
                <VerticalWhiteLine />
                {timeComponent(timeLeft.seconds, secondsFormatted(timeLeft.seconds))}
            </div>
        )}
    </div>
    );
};

export default Timer