// components/ImageUpload.js
import React, { useState, useCallback } from 'react';
import { storage } from '../../services/firebaseApp.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useDropzone } from 'react-dropzone';
import './imageUpload.css';
import upload_icon from '../../assets/upload_icon.png';
import Lottie from 'lottie-react';
import rocket_animation from '../../assets/rocket_animation.json';
import heic2any from 'heic2any';

const ImageUpload = ({loaderStatusHandler}) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [urls, setUrls] = useState([]);
  const [error, setError] = useState('');

// Handle file selection
  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setUploadedCount(0); // Reset the uploaded count when new files are selected
    setUrls([]); // Clear previous URLs

    if (selectedFiles.length > 0) {
      // Trigger upload automatically
      await handleUpload(selectedFiles);
    }
  };

  // Handle file selection through file input or dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
    setUploadedCount(0); // Reset the uploaded count when new files are selected
    setUrls([]); // Clear previous URLs

    if (acceptedFiles.length > 0) {
      // Trigger upload automatically
      handleUpload(acceptedFiles);
    }
  }, []);

  // Dropzone configuration
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  // Handle file upload to Firebase
  const handleUpload = async (selectedFiles) => {
    setUploading(true);
    setUploadedCount(0); // Reset uploaded count
    const uploadFiles = []

    for (const file of selectedFiles) {
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        console.log("File should be converted")
        const convertedBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });

        const uploadFile = {
          file: convertedBlob,
          name: file.name.split('.')[0]
        }

        uploadFiles.push(uploadFile)
      } else {
        const uploadFile = {
          file: file,
          name: file.name.split('.')[0]
        }
        uploadFiles.push(uploadFile)
      }
    }

    const promises = uploadFiles.map(async (file) => {
      const storageRef = ref(storage, `images/${Date.now()} - ${file.name}`);
      await uploadBytes(storageRef, file.file);
      const url = await getDownloadURL(storageRef);
      setUploadedCount((prev) => prev + 1); // Increment uploaded count
      return url;
    });

    try {
      const downloadUrls = await Promise.all(promises);
      setUrls(downloadUrls);
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Failed to upload images.');
      loaderStatusHandler('error_upload')
    } finally {
      setTimeout(() => {
          setUploading(false);
          loaderStatusHandler('success_upload')
      }, 1000); // 1-second delay
    }
  };

  return (
    <div className='image-upload-container'>
      <div className='image-upload-card'>
        <div 
          className='image-upload-card-border'
          {...getRootProps()}
        >
          {!uploading ? (
            <div className='image-upload-text-container'>
              <div className='image-upload-card-icon'>
                <img src={upload_icon} alt='Upload icon'></img>
              </div>
              <div className='image-upload-card-title'>
                <p>Pošaljite nam svoje slike</p>
              </div>
              <div className='image-upload-card-button-container'>
                <button 
                  className="image-upload-card-button"
                  onClick={() => document.getElementById('file-input').click()}>
                  Odaberi slike
                <input
                  id="file-input"
                  type="file"
                  accept="image/*" // Restricts to image files only
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  multiple // Enable multiple file selection
                />
                </button>
              </div>
            </ div>
          ) : (
            <div className='file-upload-container'>
              <div className='rocket_animation_container-lottie'>
                  <Lottie animationData={rocket_animation} loop={true} />
              </div>
              <div className='story_item-container-subtitle'>
                <p>
                  Slanje slika u tijeku... Spremljeno je {uploadedCount} od {files.length} slika.
                </p>
              </ div>
            </ div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;