import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseApp';

export const fetchImagesFromStorage = async () => {
  const listRef = ref(storage, '/images'); // Replace 'your-folder' with the name of the folder in Firebase Storage
  const images = [];

  try {
    // List all items (up to 1000)
    const res = await listAll(listRef);

    // Fetch the first 50 image URLs
    for (let i = 0; i < Math.min(res.items.length, 32); i++) {
      const imageUrl = await getDownloadURL(res.items[i]);
      images.push(imageUrl);
    }

    return images;
  } catch (error) {
    console.error('Error fetching images from Firebase Storage:', error);
    return [];
  }
};