import React, { useState } from 'react'
import { Navbar, Header, Schedule, Intro, Timer, Story, Loader, Popup, Memories, Grid } from './components'
import './App.css';

const App = () => {
  const [loaderState, setLoaderState] = useState('');
  const [attending, setAttending] = useState();

  const loaderStatusHandler = (status, isAttending) => {
    setLoaderState(status);
    setAttending(isAttending)
  };

  const closeHandler = () => {
    setLoaderState('');
  };

  return (
    <div className='App'>
      <Navbar />
      <Header />
      <Intro />
      <Memories loaderStatusHandler={loaderStatusHandler} />
      <Grid />
      <Schedule />
      <Story />
      <Timer />
      <Overlay loaderState={loaderState} closeHandler={closeHandler} attending={attending}/>
    </div>
  )
}

const Overlay = ({ loaderState, closeHandler, attending }) => {
  if (loaderState === 'loading') {
    return <Loader />;
  } else if (loaderState === 'success') {
    return <Popup closeHandler={closeHandler} title={attending === 'yes' ? 'Vidimo se uskoro!' : 'Žao nam je što ne možeš doći'} subtitle='Ako dođe do nekih promjena molim vas da nas obavijestite!' />;
  } else if (loaderState === 'error') {
    return <Popup closeHandler={closeHandler} title='Nešto je pošlo po krivu 😞' subtitle='Molimo te da provjeriš podatke i pokušaš ponovno' />;
  } else if (loaderState === 'success_upload') {
    return <Popup closeHandler={closeHandler} title={'Hvala ti što si s nama podijelio svoje uspomene!'} subtitle='' />;
  } else if (loaderState === 'error_upload') {
    return <Popup closeHandler={closeHandler} title='Nešto je pošlo po krivu 😞' subtitle='Molimo vas da pokušate ponovno poslati slike.' />;
  } else {
    return <div />;
  }
};
export default App
